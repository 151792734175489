import { ClassicEditor, Essentials, Bold, Italic, Paragraph, Heading, HeadingButtonsUI, ParagraphButtonUI, Link } from 'ckeditor5';
import { List as ListCK } from 'ckeditor5';
import mfConditionalFields from "mf-conditional-fields";
import List from 'list.js';
import Pristine from 'pristinejs';
import ApexCharts from 'apexcharts';
import axios, { Axios } from 'axios';

document.addEventListener('DOMContentLoaded', () => {

    //
    // Address lookup
    //
    if(window.addressField !== undefined) {
        window.addressField.activateSubfield("address-street1");
    }

    //
    // Location selctor
    //
    if(document.getElementById("locations-list")) {

        var options = {
            valueNames: ['name', 'address'],
            searchDelay: 250,
            pagination: {
                item: "<li><a class='page flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-aqua hover:bg-gray-100 hover:text-mine-shaft' href='#'></a></li>",
                innerWindow: 3
            },
            page: 6
        };

        // Show no results if zero matches
        var userList = new List('locations-list', options)
            .on('updated', function(list) {

                var noResults = document.querySelector(".list-noresults");
                
                if(list.matchingItems.length == 0) {
                    noResults.classList.remove("hidden");
                } else {
                    noResults.classList.add("hidden");
                }
                
            });
        
        // Update our selected label when the location selected changes
        document.body.addEventListener('change', function (e) {
            var selected = document.querySelector('input[name="fields[location][]"]:checked');
            if(selected !== null) {
                document.querySelector(".location-selected-name").textContent = selected.getAttribute("data-name");
            }
        });

    }

    //
    // Event schedule / date manipualtion
    //
    if(document.querySelector("#form-step-3")) {

        var id = 1;
        var buttonAddDate = document.getElementById("button-add-date");
        var datesContainer = document.getElementById("dates-container");
        var datesOrder = document.getElementById("dates-order");
        
        // Generate new "add date" markup
        function generateNewDate() {
            
            var templateId = id++;
                templateId = "new:" + templateId;
            var template = document.createElement('template');
            var templateLiteral = `
                <div data-row-id="${templateId}">
                    <input type="hidden" name="fields[specificDates][entries][${templateId}][type]" value="specificDatesBlock">
                    <div class="md:flex items-center justify-between gap-10 border border-gray-300 p-5 rounded-lg">
                        <div class="md:flex items-center gap-8 w-full">
                            <div>
                                <label for="fields-specificDates-entries-${templateId}-specificDate" class="block mb-1 font-semibold text-mine-shaft">Date</label>
                                <input type="date" required id="fields-specificDates-entries-${templateId}-specificDate" class="bg-gray-50 w-44 border border-gray-300 text-mine-shaft rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 input" name="fields[specificDates][entries][${templateId}][fields][date]" value="">
                            </div>
                            <div class="mt-4 md:mt-0">
                                <label for="fields-specificDates-entries-${templateId}-specificStartTime" class="block mb-1 font-semibold text-mine-shaft">Start time <span class="italic font-normal text-sm">(24hr)</span></label>
                                <input type="time" required id="fields-specificDates-entries-${templateId}-specificStartTime" class="bg-gray-50 w-32 border border-gray-300 text-mine-shaft rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 input" name="fields[specificDates][entries][${templateId}][fields][startTime]" value="">
                            </div>
                            <div class="grow mt-4 md:mt-0 md:text-right">
                                <a href="#" onclick="return deleteDateRow('${templateId}')" class="text-sm underline">Remove date</a>
                            </div>
                        </div>
                    </div>
                </div>`;

            template.innerHTML = templateLiteral;

            // Add a sort order field too
            datesOrder.insertAdjacentHTML("beforeend", `<input type="hidden" name="fields[specificDates][sortOrder][]" data-sort-id="${templateId}" value="${templateId}">`);

            return template;

        }

        // Delete "add date" row function - global so callable from templates
        globalThis.deleteDateRow = function(id) {

            var rowElement = document.querySelector(`[data-row-id="${id}"]`);
            var sortElement = document.querySelector(`[data-sort-id="${id}"]`);

            if(rowElement) {
                rowElement.remove();
            }

            if(sortElement) {
                sortElement.remove();
            }

            return false;

        }

        // Add date button listerner
        buttonAddDate.addEventListener("click", function(e) {
            e.preventDefault();
            
            var template = generateNewDate();
            datesContainer.appendChild(template.content);
        });

    }

    //
    // Form validation
    //
    var formElm = document.querySelector(".form-validate");

    if(formElm) {

        var config = {
            // class of the parent element where the error/success class is added
            classTo: 'field-group',
            errorClass: 'validate-error',
            successClass: 'validate-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'field-group',
            // type of element to create for the error text
            errorTextTag: 'div',
            // class of the error text element
            errorTextClass: 'validate-help' 
        };

        // create the pristine instance
        var pristine = new Pristine(formElm, config);

        // Only allow form submission if valid
        formElm.addEventListener('submit', function(e) {
            e.preventDefault();

            // Get array of "active" fields (not hidden by conditional logic)
            var activeFields = Array.from(formElm.elements);
                activeFields = activeFields.filter(function(el) {
                    return el.offsetParent !== null && el.tagName !== "BUTTON" && el.tagName !== "FIELDSET";
                });

            // Only validate active fields
            var valid = pristine.validate(activeFields);

            if (valid) {
                formElm.submit();
            }
        });

    }

    //
    // Pageview chart
    //
    var pageviewsChartElm = document.getElementById("pageviews-chart");
    
    if(pageviewsChartElm) {

        var pageviewOptions = {
            chart: {
                height: "100%",
                maxWidth: "100%",
                type: "area",
                fontFamily: "Popins, sans-serif",
                zoom: {
                    enabled: false
                },
                dropShadow: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            tooltip: {
                enabled: true,
                x: {
                    show: false,
                },
            },
            fill: {
                type: "gradient",
                gradient: {
                    opacityFrom: 0.55,
                    opacityTo: 0,
                    shade: "#D3145A",
                    gradientToColors: ["#D3145A"],
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 6,
                colors: ["#D3145A"]
            },
            grid: {
                show: false,
                strokeDashArray: 4,
                padding: {
                left: 2,
                right: 2,
                top: 0
                },
            },
            series: [],
            xaxis: {
                type: 'datetime',
                labels: {
                show: true,
                format: 'd MMM',
                },
                axisBorder: {
                show: false,
                },
                axisTicks: {
                show: false,
                },
            },
            yaxis: {
                show: false,
            },
            noData: {
                text: 'Loading...'
            }
        }

        // Init chart
        var pageviewsChart = new ApexCharts(pageviewsChartElm, pageviewOptions);
        pageviewsChart.render();
        
        // Populate chart
        var eventId = pageviewsChartElm.getAttribute("data-event-id");
        var period = pageviewsChartElm.getAttribute("data-period");

        axios({
            method: 'GET',
            url: "/actions/_event-analytics/reporting/pageviews?entryId=" + eventId + "&period=" + period,
            }).then(function(response) {
                
                var total = response.data.totalPageviews ?? 0;
                var period = response.data.dataPeriod ?? 7; 

                document.getElementById("chart-total").innerHTML = total;
                document.getElementById("period-days").innerHTML = period;

                if(response.data.data != undefined) {
                    pageviewsChart.updateSeries([{
                        name: 'Pageviews',
                        data: response.data.data,
                        color: '#D3145A'
                    }])
                }
        });

    }
        
    
    //
    // CK Editor init
    //
    var ckEditorsElms = document.querySelectorAll('.richEditor');
    
    if(ckEditorsElms) {
        
        ckEditorsElms.forEach((el) => {
        ClassicEditor
            .create(el, {
                plugins: [ Essentials, Bold, Italic, Paragraph, Heading, HeadingButtonsUI, ParagraphButtonUI, Link, ListCK ],
                toolbar: {
                    items: [
                        'undo', 'redo', '|', 'heading2', 'paragraph', 'link', 'bold', 'italic', '|', 'numberedList', 'bulletedList'
                    ]
                },
                heading: {
                    options: [
                        { model: 'heading2', view: 'h2', title: 'Heading', class: 'ck-heading_heading2' },
                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    ]
                }
            });
        });

    }

    //
    // Form conditional rules
    //
    var conditionalRuleElms = document.querySelectorAll(".form-conditional-rules");

    if(conditionalRuleElms) {

        let initialized = [];
        conditionalRuleElms.forEach((element) => {
            let form = element.closest('form');
            let formId = form.getAttribute('id');

            if (initialized.includes(formId)) {
                return;
            }

            initialized.push(formId);

            mfConditionalFields('#' + formId, {
                debug: process.env.NODE_ENV === 'development',
                rules: 'block',
            });
        });

    }

});